<template>
  <div>
    <v-dialog v-model="singleLoading" hide-overlay persistent width="300">
      <v-card outlined class="pa-5 rounded20" color="teal" dark>
        <v-card-text>
          Cargando receta...
          <v-progress-linear indeterminate color="white"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="!singleLoading && recipe"
      v-model="dialog"
      width="80vw"
      persistent
      v-on:click:outside="(dialog = false), $emit('clearrecipe')"
      scrollable
    >
      <v-card class="pa-5 rounded20" v-if="!assignedToAnotherPharmacy">
        <v-card-title class="justify-center">
          Detalle de la receta {{ recipe.id }}
        </v-card-title>
        <v-card-text class="text-center pa-2 overflow-hidden">
          <v-chip class="" color="cyan ligthen-2" dark>
            {{ unasigned ? "Sin asignar" : recipe.pharmacy.RazonSocial }}
          </v-chip>
        </v-card-text>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <v-card flat>
                  <v-list dense v-if="recipe.affiliate">
                    <v-list-item
                      v-for="propety in affiliateProperties"
                      :key="propety.key"
                    >
                      <v-list-item-avatar>
                        <v-icon size="20">
                          {{ propety.icon }}
                        </v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ propety.label }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ recipe.affiliate[propety.key] }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                  <v-chip v-else color="red" dark> Sin afiliado </v-chip>
                  <div
                    style="display: block"
                    v-for="prop in recipeProperties"
                    :key="prop.key"
                  >
                    <v-chip
                      class="my-2"
                      color="teal"
                      dark
                      v-if="!isNullOrEmpty(recipe[prop.key])"
                    >
                      {{ prop.name }}: {{ recipe[prop.key] }}
                    </v-chip>
                  </div>
                </v-card>
              </v-col>
              <v-col>
                <v-card flat>
                  <v-list dense v-if="recipe.doctor">
                    <v-list-item
                      v-for="propety in doctorProperties"
                      :key="propety.key"
                    >
                      <v-list-item-avatar>
                        <v-icon size="20">
                          {{ propety.icon }}
                        </v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ propety.label }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ recipe.doctor[propety.key] }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                  <v-list-item v-else>
                    <v-list-item-avatar> </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title> Matrícula Médico </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ recipe.doctorId }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <RecipeDetailsTable
                  :items="recipe.meds"
                  :pharmacyId="recipe.pharmacyCode"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  outlined
                  flat
                  rounded
                  name="input-7-4"
                  label="Nota"
                  v-model="recipe.note"
                  prepend-inner-icon="mdi-note-text"
                  height="40px"
                  :readonly="recipe.pharmacyCode != 0"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            rounded
            text
            @click="(dialog = false), $emit('clearrecipe')"
            v-text="'Cerrar'"
          />
          <v-btn
            v-if="unasigned"
            dark
            rounded
            :color="recipe.assignable ? 'teal' : 'red'"
            @click="assignRecipe(recipe)"
          >
            {{
              recipe.assignable ? "Asignar" : "No corresponde al período actual"
            }}
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-else>
        <v-card-text class="pa-4 d-flex flex-column justify-center align-center">
          <div class="pa-8">
            <v-icon size="60px" color="teal">
              mdi-alert-circle
            </v-icon>
          </div>
          <div class="pa-8 font-weight-bold">
            La receta ha sido asignada en otra farmacia
          </div>
      </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar
      timeout="-1"
      top
      centered
      outlined
      multi-line
      color="red"
      rounded
      v-model="error.status"
    >
      <v-sheet color="white" class="pa-2 text-h6 text-center rounded20">
        <v-icon
          color="red"
          size="50"
          class="pb-1 pt-0"
          v-text="'mdi-information-outline'"
        />

        <v-card elevation="0" style="color: negative">
          <v-card-title> No encontramos la receta</v-card-title>
          <v-card-text>
            <v-list>
              <v-list-item>- Planes B2 a través de la web de COFAER</v-list-item>
              <v-list-item>- Planes B6 y B7 provee farmacia</v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
        <v-btn color="red" rounded outlined @click="error = {}">cerrar</v-btn>
      </v-sheet>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import RecipeDetailsTable from "./RecipeDetailsTableComponent.vue";
import { isNullOrEmpty } from "../../../services/utils";
import { eventBus } from "../../../services/eventBus";

export default {
  props: ["id"],

  components: {
    RecipeDetailsTable,
  },

  name: "RecipeDetail",
  data: () => ({
    dialog: false,
    error: {},
    recipeProperties: [
      {
        name: "Fecha de prescripcion",
        key: "prescDate",
      },
      {
        name: "Fecha de autorización",
        key: "authDate",
      },
      {
        name: "Obra social",
        key: "prepaid",
      },
      {
        name: "Pedido a drogueria",
        key: "orderToDrugstore",
      },
    ],
    affiliateProperties: [
      {
        key: "NombreYApellido",
        label: "Afiliado",
        icon: "mdi-account",
      },
      {
        key: "telefono",
        label: "Teléfono",
        icon: "mdi-phone",
      },
    ],
    doctorProperties: [
      {
        key: "NombreYApellido",
        label: "Doctor",
        icon: "mdi-account",
      },
      {
        key: "Telefono",
        label: "Teléfono",
        icon: "mdi-phone",
      },
      {
        key: "Localidad",
        label: "Localidad",
        icon: "mdi-city",
      },
      {
        key: "Direccion",
        label: "Dirección",
        icon: "mdi-map-marker",
      },
    ],
  }),

  mounted() {
    eventBus.$on("recipeDetail", (id) => {
      this.fetchRecipe(id);
    });
  },
  methods: {
    ...mapActions("recipes", ["getRecipe", "assignRecipe"]),

    isNullOrEmpty(val) {
      return isNullOrEmpty(val);
    },

    async fetchRecipe(id) {
      await this.getRecipe(id).then((response) => {
        if (response.status == 2) {
          this.error = { status: true };
        }
        if (response.data) {
          this.dialog = true;
        }
      });
    },
  },

  computed: {
    ...mapGetters("recipes", ["recipe", "singleLoading"]),
    ...mapGetters("pharmacy", ["pharmacy"]),

    unasigned: function () {
      return this.recipe ? this.recipe.pharmacyCode === 0 : false;
    },
    assignedToAnotherPharmacy: function () {
      return this.recipe.pharmacyCode !== this.pharmacy.id && this.recipe.pharmacyCode !== 0
    },
  },
};
</script>
